// ON DOCUMENT READY
$(document).ready(() => {
	$('#testimonials').owlCarousel({
	    loop:true,
	    nav:false,
	    items:1,
	    autoHeight:true,
	    autoplay:true,
	    autoplayTimeout:2000,
	    autoplayHoverPause:true
	});

	$('#clock').countdown('2021/11/10', function(event) {
	  $(this).html(event.strftime('<span>%D<i>Days</i></span>:<span>%H<i>Hours</i></span>:<span>%M<i>Minutes</i></span>:<span>%S<i>Seconds</i></span>'));
	});

	$(".accordion > a").on("click", function() {
	    if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(this).siblings(".accordion__content").slideUp(200);
			$(".accordion > a .plus-wrapper").removeClass("is-active");
	    } 
	    else {
	      $(".accordion > a .plus-wrapper").removeClass("is-active");
	      $(this).find(".plus-wrapper").addClass("is-active");
	      $(".accordion > a").removeClass("active");
	      $(this).addClass("active");
	      $(".accordion__content").slideUp(200);
	      $(this).siblings(".accordion__content").slideDown(200);
	    }
  	});

  	/*-------------------------------------------------*/
	/* =  Popups
	/*-------------------------------------------------*/

	var popupTrigger = $('.popup-trigger');
	var popupClose = $('.close-popup');
	var popup = $('.popup');
	popupTrigger.on("click", function () {
		var popupTrigger = $(this).data("popup");
		popup.removeClass("is-active");
		$('html').addClass("is-active");
		$("#popup" + popupTrigger).addClass("is-active");
	});
	popupClose.on("click", function () {
		popup.removeClass("is-active");
		$('html').removeClass("is-active");
	});

	if ( $(window).width() < 991 ) {
		startCarousel();
	} else {
		$('#hero-slider').addClass('off');
	}

	$(window).resize(function() {
	    if ( $(window).width() < 991 ) {
	      startCarousel();
	    } else {
	      stopCarousel();
	    }
	});

	function startCarousel(){
	  $("#hero-slider").owlCarousel({
	     navigation : true, // Show next and prev buttons
	     slideSpeed : 500,
	     margin:20,
	     paginationSpeed : 400,
	     autoplay:false,
	     center:true,
	     items : 3,
	     itemsDesktop : false,
	     itemsDesktopSmall : false,
	     itemsTablet: false,
	     itemsMobile : false,
	     loop:true,
	     nav:true,
	     navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"]
	  });
	}
	function stopCarousel() {
	  var owl = $('#hero-slider');
	  owl.trigger('destroy.owl.carousel');
	  owl.addClass('off');
	}
});
